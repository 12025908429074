//fix for shimming jQuery inside of libs
import './import-jquery';

//local imports
import './jquery.backgroundMove';
import './scrollUp.js';
import './meanmenu.min.js';

//libraries
import 'bootstrap';
import AOS from 'aos';
import ScrollOut from "scroll-out";
import slick from 'slick-carousel';
import counterUp from 'counterup2';

const jQueryBridget = require('jquery-bridget');
const Isotope = require('isotope-layout');
require('magnific-popup/dist/jquery.magnific-popup.js');

jQueryBridget('isotope', Isotope, $);


(function ($) {
	"use strict";
	/*----------------------------
	Animation js active
	------------------------------ */
	AOS.init();
	/*----------------------------
	Counter-up
	------------------------------ */
	ScrollOut({
		onShown: function (el) {
			counterUp(el, {
				delay: 10,
				time: 1000
			});
		},
		once: true
	});
	/*----------------------------
     Video Popup JS
	----------------------------*/
	
	$('.popup-youtube').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	/*-----------------------------
Portfolio Carousel
------------------------------*/

	$('.portfolio-item-slider').slick({
		dots: false,
		arrows: true,
		prevArrow: "<i class='fas fa-chevron-left left-arrow'></i>",
		nextArrow: "<i class='fas fa-chevron-right right-arrow'></i>",
		infinite: true,
		lazyLoad: 'ondemand',
		autoplay: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					dots: false
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					dots: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false
				}
			}

		]
	});
	/*-----------------------------
Testimonial Carousel
------------------------------*/
	$('.testimonial-slider').slick({
		dots: false,
		arrows: true,
		prevArrow: "<i class='fas fa-chevron-left left-arrow-2'></i>",
		nextArrow: "<i class='fas fa-chevron-right right-arrow-2'></i>",
		infinite: true,
		autoplay: false,
		speed: 1200,
		slidesToShow: 1,
		slidesToScroll: 1,
	});
	/*-----------------------------
Project Carousel
------------------------------*/
	$('.single-project-slider').slick({
		dots: false,
		arrows: true,
		prevArrow: "<i class='fas fa-chevron-left left-arrow-3'></i>",
		nextArrow: "<i class='fas fa-chevron-right right-arrow-3'></i>",
		infinite: true,
		autoplay: true,
		speed: 1200,
		slidesToShow: 1,
		slidesToScroll: 1,
	});

	/*----------------------------
	Search
	------------------------------ */
	var changeClass = function (name) {
		$('#search, .search-icon-area a').removeAttr('class').addClass(name);
	}
	/*----------------------------
	jQuery Mean Menu
	------------------------------ */
	$("#mobile-menu").meanmenu({
		meanMenuContainer: ".mobile-menu",
		meanScreenWidth: "767"
	});
	$(".info-bar").on("click", function () {
		$(".extra-info").addClass("info-open");
	});
	$(".close-icon").on("click", function () {
		$(".extra-info").removeClass("info-open");
	});
	/*----------------------------
    Sticky menu active
    ------------------------------ */
	function fixed_top_menu() {
		var windows = $(window);
		windows.on("scroll", function () {
			//switch logos
			var header_height = $(".main-navigation").height();
			var scrollTop = windows.scrollTop();
			if (scrollTop > header_height) {
				$(".main-navigation").addClass("sticky");
				$('.main-navigation .logo-link.variant-1').addClass('inactive');
				$('.main-navigation .logo-link.variant-2').removeClass('inactive');
			} else {
				$(".main-navigation").removeClass("sticky");
				$('.main-navigation .logo-link.variant-1').removeClass('inactive');
				$('.main-navigation .logo-link.variant-2').addClass('inactive');
			}
		});
	}
	fixed_top_menu();

	/*-----------------
	Scroll-Up
	-----------------*/
	$.scrollUp({
		scrollText: '<i class="far fa-arrow-alt-circle-up"></i>',
		easingType: 'linear',
		scrollSpeed: 1000,
		animation: 'fade'
	});
	/*-----------------
    POrtfolio Filter
    -----------------*/
	var $grid = $('.grid').isotope({
		percentPosition: true,
	})
	$('.portfolio-filter').on('click', 'a', function (e) {
		e.preventDefault();
		$(this).parent().addClass('active').siblings().removeClass('active');
		var filterValue = $(this).attr('data-filter');
		$grid.isotope({
			filter: filterValue
		});
	});

	/*----------------------------
	       Menu active JS
	     ----------------------------*/
	$('.portfolio-filter ul li a').on('click', function () {
		$('.portfolio-filter ul li a').removeClass("current");
		$(this).addClass("current");
	});

	$('.main-menu ul li a').on('click', function () {
		$('.main-menu ul li a').removeClass("current");
		$(this).addClass("current");
	});
	/*----------------------------
	Preloader
	------------------------------ */
	$(window).on('load', function () {
		$("#preloader").delay('300').fadeOut("slow", function complete() {
			$(this).remove();
		});
	});
	/*----------------------------
	Move Background
	------------------------------ */
	/*
	$(function () {
		$('.big-footer').backgroundMove();
	});
	*/

	$('#mainContactForm').on('submit',function(){
        event.preventDefault();
        var check = true;
		var input = $('.validate-input');
        for(var i=0; i<input.length; i++) {
            //validate form inputs

            if(validate(input[i]) == false){
                showValidate(input[i]);
                check=false;
            }
        }

        if(check == true){
            grecaptcha.execute();
        }            
        else{
            return false;
        }
	});

	$('.validate-input').each(function(){
        $(this).focus(function(){
           hideValidate(this);
        });
	});

	function validate (input) {
		if($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
			if($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
				return false;
			}
		}
		else {
			if($(input).val().trim() == ''){
				return false;
			}
		}
	}
	
	function showValidate(input) {
		var thisAlert = $(input).parent();
	
		$(thisAlert).addClass('alert-validate');
	}
	
	function showCaptchaValidate(input) {
		var thisAlert = $(input).parent();
	
		$(thisAlert).addClass('.alert-recaptcha-validate');
	}
	
	function hideValidate(input) {
		var thisAlert = $(input).parent();
	
		$(thisAlert).removeClass('alert-validate');
	}

	function onRecaptchaError(err){
		$('.success-message').addClass('invisible');
		$('.error-message').removeClass('invisible');
	}
})(jQuery);